<template>
  <AuthGuard>
    <v-app app>
      <v-app-bar color="accent" app>
        <v-col cols="4">
          <v-img
            id="uaBrand"
            src="@/assets/ua_brand.png"
            max-width="250"
            class="ml-auto"
          ></v-img>
        </v-col>
      </v-app-bar>
      <v-main>
        <router-view></router-view>
      </v-main>
      <v-footer app padless>
        <v-card
          flat
          tile
          width="100%"
          color="primary_light"
          class="d-flex justify-space-between ma-0"
        >
          <v-card-text color="primary_light">
            <v-img src="@/assets/ua_brand.png" contain width="250"></v-img>
          </v-card-text>
          <v-card-text color="primary_dark" class="mt-2 text-subtitle-1">
            Contact Dev: 333-4444 or dev@arizona.edu
          </v-card-text>
          <v-card-text
            id="addressText"
            color="primary_dark"
            class="mt-2 text-subtitle-1"
          >
            Financial Services 888 N. Euclid Ave. Room 502, Tucson, AZ 85721
          </v-card-text>
        </v-card>
      </v-footer>
    </v-app>
  </AuthGuard>
</template>

<script>
import AuthGuard from "@uarizona-fnsv/vue-doggo/AuthGuard"

export default {
  name: "App",
  components: {
    AuthGuard,
  },

  beforeMount() {
    this.$auth.login()
  },
}
</script>

<style lang="scss">
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-material.css";
</style>
