import Vue from "vue"
import Swal from "sweetalert2"
import axios from "axios"
import Doggo from "@uarizona-fnsv/vue-doggo"
import * as Sentry from "@sentry/browser"
import { Vue as VueIntegration } from "@sentry/integrations"
import { LicenseManager } from "ag-grid-enterprise"

import App from "./App.vue"
import router from "./router"
import api from "./utils/api"
import store from "./store"
import vuetify from "./plugins/vuetify"

// Sentry configuration
function getEnvironment() {
  const hostname = window.location.hostname
  if (hostname.includes("-dev")) return "dev"
  if (hostname.includes("cloudfront.net")) return "review"
  return "prod"
}

LicenseManager.setLicenseKey(
  "CompanyName=University of Arizona Financial Services Office,LicensedGroup=Financial Services-IT,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=0,AssetReference=AG-015281,ExpiryDate=12_August_2022_[v2]_MTY2MDI1ODgwMDAwMA==116e7222abf2813c45918e7c8b238dd9",
)

if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [new VueIntegration({ Vue, attachProps: true })],
    environment: getEnvironment(),
  })
}

Vue.config.productionTip = false

Vue.use(Doggo, {
  amplify: {
    region: process.env.VUE_APP_COGNITO_REGION,
    userPoolWebClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
    userPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
    domain: process.env.VUE_APP_COGNITO_DOMAIN,
    redirectSignIn: process.env.VUE_APP_COGNITO_SIGN_IN_URL,
  },
  disable: process.env.VUE_APP_DISABLE_AUTH,
})

// Add global methods
Vue.prototype.$http = axios
Vue.prototype.$api = api // For internal API, comes with CSRF token.
Vue.prototype.$alert = Swal

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app")
