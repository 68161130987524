import Vue from "vue"
import Router from "vue-router"

Vue.use(Router)

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "grid",
      meta: { title: "AgGrid" },
      component: () => import("./views/AgGrid.vue"),
    },
  ],
})

router.beforeEach((to, from, next) => {
  document.title = to?.meta.title ? `myapp | ${to.meta.title}` : "myapp"
  next()
})

export default router

// comment test
// Fixed the comment
